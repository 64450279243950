<template>
  <cks-combined-route>
    <div class="reception-cold-storage">
      <div class="header">
        <div class="input-box" v-if="!flag">
          <input placeholder="输入城市或冷库名字" class="search-ipt" type="text" ref="inputValue" @keyup.enter="showSearch">
          <div @click="showSearch" class="search-btn">搜 索</div>
        </div>
        <img src="@/assets/images/coldStorage-image/lengku.png" alt="">
      </div>
      <div class="input-box-phone" v-if="flag">
        <input placeholder="输入城市或冷库名字" class="search-ipt" type="text" ref="inputValue" @keyup.enter="showSearch">
        <div @click="showSearch" class="search-btn">搜 索</div>
      </div>
      <div class="w content">
        <el-empty v-if="records.length <= 0" description="暂无数据" />

        <el-row v-else :gutter="50">
          <el-col :span="8" v-for="item in records" :key="item" :sm="12" :md="8" :xs="24">
            <div class="hidden-acitve margin-bottom">
              <div class="exhibition curr hvr-grow" @click="toDetail(item)">
                <div class="title-box">
                  <div class="title">{{ item.name }}</div>
                  <!-- <div class="address">
                    <div class="img-box">
                      <img src="@/assets/images/home-image/icon.png" alt="">
                    </div>
                    <span>{{ item.address }}</span>
                  </div> -->
                </div>
                <img :src="item.titlePic" alt="">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="pagination-box">
        <cks-pagination v-model="pagination" :total="total"></cks-pagination>
      </div>
    </div>
  </cks-combined-route>
</template>

<script>
import {
  reactive, watch, watchEffect, toRefs, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import usePageSize from '@/hooks/use-page-size';
import apis from '@/request/apis';
import loading from '@/assets/images/loading.png';
import { proxyImg } from '@/utils';

export default {
  setup() {
    const pageWidth = usePageSize();
    const router = useRouter();
    const data = reactive({
      loading,
      flag: false,
      pagination: {
        current: 1,
        size: 9,
      },
      total: 0,
      conditions: [],
      records: [],
      inputValue: null,
    });
    const toDetail = (row) => {
      router.push({
        name: 'receptionColdStorageEdit', params: row,
      });
    };

    async function load() {
      const res = await apis.receptionColdStorage.getPage({ ...data.pagination, conditions: data.conditions });
      data.records = res.records;
      data.total = res.total;
      data.records.forEach((item) => {
        proxyImg(item.titlePic, () => {
          item.titlePic = data.loading;
        }, (url) => {
          item.titlePic = url;
        });
      });
    }

    watchEffect(() => {
      if (data.pagination) {
        load();
      }
    });

    function showSearch() {
      data.conditions = JSON.stringify([{ name: 'city', opr: 'like', value: data.inputValue.value }]);
      load();
    }

    onMounted(() => {
      const width = document.body.clientWidth;
      data.flag = width <= 768;
    });

    watch(() => pageWidth, (newVal) => {
      const width = newVal.pageWidth;
      data.flag = width <= 768;
    }, { deep: true });

    return {
      ...toRefs(data),
      toDetail,
      showSearch,
    };
  },
};
</script>

<style scoped lang="scss">
.reception-cold-storage {
  margin: 0 auto;
  .header {
    width: 100%;
    position: relative;
    &>img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .input-box {
      position: absolute;
      z-index: 2;
      width: 500px;
      height: 40px;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      border-radius: 8px;
      overflow: hidden;
      font-size: 20px;
      .search-ipt {
        flex: 1;
        background-color: rgba(255, 255, 255, 0.7);
        padding-left: 20px;
        padding-right: 160px;
        border: 0px;
        outline: none;
        font-size: 16px;
      }
      .search-btn {
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        border-radius: 8px;
        position: absolute;
        right: 0;
        cursor: pointer;
      }
    }
  }
  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #444;
    font-size: 16px;
  }
  input:-moz-placeholder, textarea:-moz-placeholder {
    color: #444;
    font-size: 16px;
  }
  input::-moz-placeholder, textarea::-moz-placeholder {
    color: #444;
    font-size: 16px;
  }
  input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #444;
    font-size: 16px;
  }

  .content {
    padding: 100px 15px 0px;
    .margin-bottom{
      margin-bottom: 50px;
    }
    .exhibition {
      height: 310px;
      position: relative;
      display: block;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .title-box {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;
        background-color: rgba(49, 73, 159, 0.7);
        padding: 0 30px;
        font-size: 14px;
        color: #FFFFFF;
        .title {
          font-size: 18px;
          height: 60px;
          line-height: 60px;
        }
        .address {
          display: flex;
          align-items: center;
          overflow: hidden;
          > span {
            white-space: nowrap;
            overflow:hidden;
            display: block;
            text-overflow: ellipsis;
          }
          .img-box {
            width: 14px;
            height: 18px;
            margin-right: 6px;
          }
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }
      }
    }
  }

  .pagination-box {
    display: flex;
    justify-content: center;
    padding: 60px 0px;
  }
}
/* 当页面宽度大于1200px小于1700ppx */
@media screen and (min-width:1200px) and (max-width: 1700px) {
  .reception-cold-storage {
    margin: 0 auto;

    .content {
      padding: 70px 15px 0px;
      .margin-bottom{
        margin-bottom: 30px;
      }
      .exhibition {
        height: 210px;
        position: relative;
        .title-box {
          padding: 0px 30px;
          font-size: 12px;
          color: #FFFFFF;
          .title {
            font-size: 16px;
          }
          .address {
            display: flex;
            align-items: center;
            .img-box {
              width: 14px;
              height: 18px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
/* 当页面宽度大于992px小于1200ppx */
@media screen and (min-width:992px) and (max-width: 1200px) {
  .reception-cold-storage {
    margin: 0 auto;

    .content {
      padding: 70px 15px 0px;
      .margin-bottom{
        margin-bottom: 30px;
      }
      .exhibition {
        height: 172px;
        position: relative;
        .title-box {
          padding: 0px 26px;
          font-size: 12px;
          color: #FFFFFF;
          .title {
            font-size: 14px;
          }
          .address {
            display: flex;
            align-items: center;
            .img-box {
              width: 12px;
              height: 14px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
/* 当页面宽度大于768px小于992ppx */
@media screen and (min-width: 768px) and (max-width: 992px) {
  ::v-deep.el-row {
    margin: 0 !important;
  }
  ::v-deep.el-col {
    padding: 0 !important;
  }
  .reception-cold-storage {
    margin: 0 auto;
    margin-top: 60px;

    .content {
      padding: 70px 15px 0px;
      .margin-bottom{
        margin-bottom: 30px;
      }
      .exhibition {
        height: 205px;
        position: relative;
        .title-box {
          padding: 0px 26px;
          font-size: 12px;
          color: #FFFFFF;
          .title {
            font-size: 14px;
          }
          .address {
            display: flex;
            align-items: center;
            .img-box {
              width: 12px;
              height: 14px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
/* 当页面宽度小于768px */
@media screen and (max-width: 768px) {
  ::v-deep.el-row {
    margin: 0 !important;
  }
  ::v-deep.el-col {
    padding: 0 !important;
  }
  .reception-cold-storage {
    margin: 0 auto;
    margin-top: 60px;
    .input-box-phone {
      margin: 0 auto;
      width: 100%;
      height: 60px;
      display: flex;
      overflow: hidden;
      font-size: 20px;
      background: #31499F;
      padding: 10px 15px;
      position: relative;
      .search-ipt {
        flex: 1;
        background-color: rgba(255, 255, 255, 0.7);
        padding-left: 20px;
        padding-right: 160px;
        border: 0px;
        outline: none;
        font-size: 14px;
        border-radius: 8px;
      }
      .search-btn {
        width: 80px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        border-radius: 8px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        cursor: pointer;
      }
    }
    .content {
      padding: 20px 15px 0px;
      .margin-bottom{
        margin-bottom: 30px;
      }
      .exhibition {
        height: calc((100vw - 50px) * 310 / 520);
        position: relative;
        .title-box {
          padding: 0px 26px;
          font-size: 12px;
          color: #FFFFFF;
          .title {
            font-size: 14px;
          }
          .address {
            display: flex;
            align-items: center;
            .img-box {
              width: 12px;
              height: 14px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
</style>
